// src/service_clients/ApiClient.js

import { fetchEventSource } from "@microsoft/fetch-event-source";

class ApiClient {
    static ProductsKey = 'products';
    static AssistantMessageKey = 'assistantMessage';
    static AssistantStatusMessageKey = 'assistantStatusMessage';
    static DoneKey = 'done';
    static CategoriesKey = 'categories';
    static apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
    static debugMode;

    static setDebugMode(debugMode) {
        this.debugMode = debugMode;
    }

    /**
     * Logs messages to the console if in development mode or if debugMode is true.
     * @param {*} message - The message to log.
     */
    static log = (message) => {
        if (process.env.REACT_APP_ENVIRONMENT === "development" || this.debugMode) {
            console.log(message);
        }
    }

    /**
     * Logs error messages to the console if in development mode or if debugMode is true.
     * @param {*} message - The error message to log.
     */
    static error = (message) => {
        if (process.env.REACT_APP_ENVIRONMENT === "development" || this.debugMode) {
            console.error(message);
        }
    }

    static getIcebreaker = (merchantId, productInternalId, conversationId, callback) => {
        const url = `${this.apiBaseUrl}/v1/merchants/${merchantId}/conversations/icebreaker`;
        const body = {
            productInternalId,
            conversationId
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            // If we get an HTTP "No Content" response, that means there are no recommendations for the user and we should not show the cart assistant
            if (response.status === 204) {
                callback(null, null);
                return;
            }

            if (!response.ok) {
                throw new Error(`Failed to fetch icebreaker: ${response.statusText}`);
            }
            return response.json();
        })
        .then(icebreakerResponseDto => {
            callback(icebreakerResponseDto, null);
        })
        .catch(error => {
            this.error(`Error fetching icebreaker: ${error.message}`);
            callback(null, error);
        });
    }

    /**
     * Handles the incoming SSE event by parsing the data and invoking the appropriate callback.
     * @param {MessageEvent} event - The SSE message event.
     * @param {Object} callbacks - The callback functions to handle different event types.
     */
    static handleMessage = (event, callbacks) => {
        try {
            // Parse the incoming data as JSON
            const eventType = event.event;
            const eventData = event.data;

            // Determine the event type and invoke the corresponding callback
            switch (eventType) {
                case this.ProductsKey:
                    const searchResultResponseDto = JSON.parse(eventData);
                    if (callbacks.onSearchResultsReceived) {
                        callbacks.onSearchResultsReceived(searchResultResponseDto);
                    }
                    break;

                case this.AssistantMessageKey:
                    if (callbacks.onMessageChunkReceived) {
                        callbacks.onMessageChunkReceived(eventData);
                    }
                    break;

                case this.AssistantStatusMessageKey:
                    if (callbacks.onAssistantStatusUpdate) {
                        callbacks.onAssistantStatusUpdate(eventData);
                    }
                    break;

                case this.DoneKey:
                    // The 'done' event signifies the end of the stream
                    this.log(`Received done event`);
                    if (callbacks.onComplete) {
                        callbacks.onComplete();
                    }
                    break;

                default:
                    this.log(`Unknown event type: '${eventType}'`);
            }
        }
        catch (error) {
            this.error(`Error parsing event data: ${error.message}`);
            if (callbacks.onError) {
                callbacks.onError(error);
            }
        }
    }

    /**
     * Gets the merchant with the given ID
     * @param {string} merchantId - The merchant ID.
     * @param {function} callback - Callback for when the request is complete.
     */
    static getMerchant = (merchantId, callback) => {
        const url = `${this.apiBaseUrl}/v1/merchants/${merchantId}`;

        this.log(`Sending GET request to ${url} to fetch merchant data with ID ${merchantId}`);
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    // Instead of throwing and then trying to call callback, just throw
                    throw new Error(`Failed to fetch merchant data: ${response.statusText}`);
                }

                // Return the parsed JSON data
                return response.json();
            })
            .then((data) => {
                this.log(`Received merchant data:`);
                this.log(data, true);
                callback(data, null);
            })
            .catch((error) => {
                this.error(`Error fetching merchant data: ${error.message}`);
                callback(null, error);
            });
    }

    /**
     * Helper method to construct the DTO used for a conversation search.
     *
     * @param {object} params - The search parameters.
     * @param {number} params.limit - The maximum number of products to retrieve.
     * @param {number} params.offset - The offset for the search results.
     * @param {Array} params.messages - The array of messages that led to this search result.
     * @param {string} params.conversationId - The conversation ID.
     * @param {Array} params.displayedCategories - A list of categories and their associated product variants that the user is seeing.
     * @param {any} params.structuredFilters - Any structured filter information.
     * @param {boolean} params.searchImmediately - Whether to search immediately.
     * @param {string} params.cartProductVariantInternalId - The internal ID of the product variant in the cart, if any.
     * @param {Array} params.pageContents - The contents of the page.
     * @returns {object} The constructed DTO.
     */
    static buildConversationRequestDto(params) {
        // Clean the messages so that only content and role are sent.
        const cleanMessages = params.messages
            ? params.messages.map(({ content, role, productVariants }) => ({ content, role, productVariants }))
            : null;
        const shopperCountry = Intl.DateTimeFormat().resolvedOptions().locale;

        return {
            limit: params.limit,
            offset: params.offset,
            messages: cleanMessages,
            conversationId: params.conversationId,
            displayedCategories: params.displayedCategories,
            structuredFilters: params.structuredFilters,
            shopperCountry: shopperCountry,
            searchImmediately: params.searchImmediately,
            cartProductVariantInternalId: params.cartProductVariantInternalId,
            pageContents: params.pageContents
        };
    }

    /**
     * Initiates a search request and handles streaming responses.
     * @param {string} merchantId - The merchant ID.
     * @param {object} conversationRequestDto - The conversation request DTO.
     * @param {function} onSearchResultsReceived - Callback for when products are received.
     * @param {function} onMessageChunkReceived - Callback for when assistant messages are received.
     * @param {function} onComplete - Callback for when the stream is complete.
     * @param {function} onError - Callback for handling errors.
     * @param {function} onAssistantStatusUpdate - Callback for when assistant status updates are
     */
    static sendMessage = (
        merchantId,
        conversationRequestDto,
        onSearchResultsReceived,
        onMessageChunkReceived,
        onComplete,
        onError,
        onAssistantStatusUpdate
    ) => {
        const url = `${this.apiBaseUrl}/v1/merchants/${merchantId}/conversations`;

        this.log(`Sending search request to ${url} with payload:`);
        this.log(conversationRequestDto);

        fetchEventSource(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/event-stream',
            },
            body: JSON.stringify(conversationRequestDto),
            /**
             * Called when the connection to the SSE endpoint is opened.
             * @param {Response} response - The HTTP response.
             */
            onopen(response) {
                if (!response.ok || response.status !== 200) {
                    throw new Error("Failed to connect to SSE endpoint");
                }
                ApiClient.log("Connected to SSE endpoint.");
            },
            /**
             * Handles incoming messages via the generic onmessage handler.
             * @param {MessageEvent} event - The SSE message event.
             */
            onmessage: (event) => {
                this.handleMessage(event, {
                    onSearchResultsReceived,
                    onMessageChunkReceived,
                    onComplete,
                    onError,
                    onAssistantStatusUpdate
                });
            },
            /**
             * Handles errors during the SSE connection.
             * @param {Error} err - The error encountered.
             */
            onerror(err) {
                ApiClient.error(`Error during search: ${err.message}`);
                if (onError) {
                    onError(err);
                }
            },
        });
    }

    /**
     * Fires an event to the backend without waiting for a response.
     * @param {string} merchantId - The merchant ID.
     * @param {Object} event - The event object to send.
     */
    static sendEvent = (merchantId, event) => {
        const url = `${this.apiBaseUrl}/v1/merchants/${merchantId}/events`;

        this.log(`Firing event to ${url} with payload:`, event);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event),
            keepalive: true // Ensures the request is sent even if the page is unloading
        })
        .then(response => {
            if (!response.ok) {
                this.error(`Failed to fire event: ${response.status} ${response.statusText}`);
            }
            else {
                this.log(`Event fired successfully with status ${response.status}`);
            }
        })
        .catch(error => {
            this.error(`Error firing event: ${error.message}`);
        });
    }
}

export default ApiClient;
