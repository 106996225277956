/* global __webpack_public_path__, __CSS_VERSION__ */
// src/index.js

/*!
  ╔════════════════════════════════════════════════════════════╗
  ╠═ Nobi is an AI personal shopping assistant for ecom       ═╣
  ╠═ companies. We help your customers search products, get   ═╣
  ╠═ customer support, and find recommended products. To get  ═╣
  ╠═ a personal assistant for your own store, visut us at     ═╣
  ╠═ https://www.nobi.ai.                                     ═╣
  ╚════════════════════════════════════════════════════════════╝
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter, Route, Routes} from "react-router-dom";
import {ShopifyEventSubscriber} from "./utils/ShopifyEventSubscriber";

const App = React.lazy(() => import('./App'));
const TestChatPage = React.lazy(() => import('./pages/TestChatPage'));
const TestCardPage = React.lazy(() => import('./pages/TestCardPage'));


const AppContainerName = 'nobi-app-container';

const createAppContainer = () => {
    let container = document.getElementById(AppContainerName);
    if (!container) {
        container = document.createElement('div');
        container.id = AppContainerName;
        document.body.appendChild(container);
    }
    return container;
};

if (process.env.NODE_ENV === 'production' && !document.getElementById('nobi-main-css')) {
    const link = document.createElement('link');
    link.id = 'nobi-main-css';
    link.rel = 'stylesheet';
    link.href = __webpack_public_path__ + 'main.css?version=' + __CSS_VERSION__;
    document.head.appendChild(link);
}

const showConsoleBanner = () => {
    const banner = `
╔═══════════════════════════════════════════════════════════════════════╗
╠═                                                                     ═╣
╠═     888b    888          888      d8b                               ═╣ 
╠═     8888b   888          888      Y8P                               ═╣ 
╠═     88888b  888          888                                        ═╣ 
╠═     888Y88b 888  .d88b.  88888b.  888                               ═╣
╠═     888 Y88b888 d88""88b 888 "88b 888                               ═╣
╠═     888  Y88888 888  888 888  888 888                               ═╣ 
╠═     888   Y8888 Y88..88P 888 d88P 888                               ═╣ 
╠═     888    Y888  "Y88P"  88888P"  888                               ═╣ 
╠═                                                                     ═╣
╠═     Looking for your own AI personal shopping assistant?            ═╣ 
╠═     Check out Nobi at https://www.nobi.ai.                          ═╣
╠═                                                                     ═╣
╚═══════════════════════════════════════════════════════════════════════╝
`;
    console.log('%c ' + banner, 'color: #D642B5;');
}

function renderApp(merchantId, debugMode, assistantConfiguration) {
    const container = createAppContainer();
    const root = ReactDOM.createRoot(container);
    root.render(
        <HashRouter>
            <Routes>
                <Route
                    path="test-chat"
                    element={
                        <TestChatPage
                            merchantId={merchantId}
                            debug={debugMode}
                        />
                    }
                />
                <Route
                    path="test-card"
                    element={
                        <TestCardPage
                            merchantId={merchantId}
                            debug={debugMode}
                            providedAssistantConfiguration={assistantConfiguration}
                        />
                    }
                />
                <Route
                    path="/*"
                    element={
                        <App
                            merchantId={merchantId}
                            providedAssistantConfiguration={assistantConfiguration}
                            debugMode={debugMode}
                        />
                    }
                />
            </Routes>
        </HashRouter>
    );
}

/**
 * Initializes the Nobi Search component with the given configuration.
 * @param {Object} config
 * @param {string} config.merchantId
 */
export const initialize = (config) => {
    const {merchantId, assistantConfiguration, debugMode} = config;
    if (!merchantId) {
        console.error('Merchant ID is required to initialize Nobi Search.');
        return;
    }
    showConsoleBanner();
    renderApp(merchantId, debugMode, assistantConfiguration);
};

/**
 * Subscribes to standard Shopify events
 *
 * @param {string} merchantId - The merchant ID
 */
export const subscribeToShopifyEvents = (merchantId, analytics) => {
    ShopifyEventSubscriber.subscribeToShopifyEvents(merchantId, analytics);
}

/**
 * Export the ShouldSeeNobi function so everyone can call it
 */
export {default as ShouldSeeNobi} from "./utils/ShouldSeeNobi";