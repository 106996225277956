const Constants = {
    SearchLimit: 18,
    MessageFormPlaceholderForNewSearch: "What are you looking for?",
    MessageFormPlaceholderForFiltering: "Filter these results...",
    MaxWidthForMobile: 650,

    // Event Tracking
    MasterLocalStorageKey: "Nobi",
    KeyForClickedProductsInLocalStorage: "nobi-clicked-products",
    KeyForConversationIdInLocalStorage: "nobi-conversation-id",
    UrlParameterForNobiConversationId: "nobi-conversation-id",
    UrlParameterForNobiProductVariantId: "nobi-product-variant-id",

    // A/B Test Groups
    KeyForNobiAbTestRandomNumber: "nobi-ab-test-random-number",
    AbTestTreatmentGroupPercentage: 0.5,
    Control: "control",
    Treatment: "treatment",

    // Custom Events
    ProductAddedToCartEvent: "nobiProductAddedToCart"
}

export default Constants;