export const Events = {
    // Entry points
    ClickOnSearchIcon: "Click On Search Icon",
    ClickSearchWithAiButton: 'Click Search With AI Button',
    RespondToCartAssistant: 'Respond To Cart Assistant',
    CartDrawerAssistantViewed: 'Cart Drawer Assistant Viewed',
    QuickAddProduct: 'Quick Add Product',

    // Search events
    Search: 'Search',
    SearchOnShopify: 'Search On Shopify',
    SearchOnNobi: 'Search On Nobi',
    ClickOnSearchResult: "Click On Search Result",
    ProductViewed: "Product Viewed",
    ProductAddedToCart: 'Product Added To Cart',
    ProductRemovedFromCart: 'Product Removed From Cart',
    CheckoutCompleted: 'Checkout Completed',
    CloseDrawer: "Close Drawer",
    ClickSearchWithoutAiButton: 'Click Search Without AI Button',

    // Chat events
    ClickOnShowMoreProductsButton: 'Click On Show More Products Button',
    SendMessage: 'Send Message',
    StartNewConversation: 'Start New Conversation',

    // Constants for built-in Shopify events that we catch with our Shopify Metrics Subscriber code
    ShopifyProductAddedToCartMetric: "product_added_to_cart",
    ShopifyProductViewedMetric: "product_viewed",
    ShopifySearchSubmittedMetric: "search_submitted",
    ShopifyProductRemovedFromCartMetric: "product_removed_from_cart",
    ShopifyCheckoutCompletedMetric: "checkout_completed"
}

export default Events;