// src/utils/AbTestHelper.js

import Constants from "./Constants";

function getOrCreateStoredRandomNumberForUser() {
    let userRandomNumber = localStorage.getItem(Constants.KeyForNobiAbTestRandomNumber);
    if (userRandomNumber === null) {
        userRandomNumber = Math.random();
        localStorage.setItem(Constants.KeyForNobiAbTestRandomNumber, userRandomNumber);
    }
    else {
        userRandomNumber = parseFloat(userRandomNumber);
    }

    return userRandomNumber;
}

/**
 * Determines whether a user should see Nobi
 *
 * @param {Object} merchant The merchant
 * @returns {boolean} True if the user should see Nobi, false otherwise
 */
export const ShouldSeeNobi = (merchant) => {
    if (!merchant && window.Nobi?.merchant) {
        merchant = window.Nobi.merchant;
    }

    // If the URL contains the hash param #show-nobi, always show Nobi
    if (window.location.hash.includes('show-nobi')) {
        return true;
    }

    let userRandomNumber = getOrCreateStoredRandomNumberForUser();
    let shouldUserSeeNobi = false;
    if (!merchant.isAbTestActive) {
        shouldUserSeeNobi = userRandomNumber < (merchant.trafficAllocationPercentage ?? 1.0);
    }
    else {
        const isInExperimentalGroup = userRandomNumber < Constants.AbTestTreatmentGroupPercentage;
        let isUserInTrafficAllocationGroup = userRandomNumber < (merchant.trafficAllocationPercentage ?? 1.0);
        shouldUserSeeNobi = isInExperimentalGroup && isUserInTrafficAllocationGroup;
    }
    return shouldUserSeeNobi;
};

export default ShouldSeeNobi;
