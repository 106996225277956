// src/utils/NobiEventTracker.js

import Constants from "./Constants";
import Events from "./Events";
import ApiClient from "../service_clients/ApiClient";
import MixpanelClient from "../service_clients/MixpanelClient";

class NobiEventTracker {
    /**
     * Called to set up click listeners for "Add To Cart" and "Shop Pay" buttons.
     *
     * @param {string} merchantId - Merchant ID from the initialize config
     * @param {string} name - The name of the event to track
     * @param {boolean} trackAllEvents - Flag to let us know if we should track events not related to Nobi
     * @param {boolean} didUserHaveAccessToNobi - Flag to let us know if the user had access to Nobi
     * @param {Object} details - Additional details to include in the event
     */
    static trackEvent(merchantId, name, trackAllEvents, didUserHaveAccessToNobi, details) {
        if (window.location.href.indexOf("localhost") > -1) {
            console.log(`On localhost, not tracking event with event logger: ${name} with details: ${JSON.stringify(details)}`);
            return;
        }

        const ShopifyEvents = [
            Events.ShopifyProductAddedToCartMetric,
            Events.ShopifyProductViewedMetric,
            Events.ShopifySearchSubmittedMetric,
            Events.ShopifyProductRemovedFromCartMetric,
            Events.ShopifyCheckoutCompletedMetric
        ];

        if (!merchantId) {
            console.error('Merchant ID is required to track events.');
            return;
        }

        if (!details) {
            details = {};
        }

        const params = new URLSearchParams(window.location.search);
        let conversationId = params.get(Constants.UrlParameterForNobiConversationId) || details.conversationId;

        if (name === Events.CheckoutCompleted) {
            let nobiData = localStorage.getItem(Constants.MasterLocalStorageKey);
            nobiData = nobiData ? JSON.parse(nobiData) : {};
            let lastConversationId = nobiData[Constants.KeyForConversationIdInLocalStorage];
            if (lastConversationId) {
                conversationId = lastConversationId;
            }
        }

        if (conversationId) {
            details.conversationId = conversationId;
        }

        if (window.Shopify?.analytics?.publish) {
            window.Shopify.analytics.publish(name, details);
        }

        // If we don't want to track all events (for example, if we're not running an A/B test) and the event is a Shopify event, then return
        if (!trackAllEvents && ShopifyEvents.includes(name)) {
            return;
        }

        if (didUserHaveAccessToNobi !== null && didUserHaveAccessToNobi !== undefined) {
            details["Nobi Did User Have Nobi"] = didUserHaveAccessToNobi;
        }

        const event = {
            name: name
        }

        if (details) {
            event.details = details;
        }

        // If we are running an A/B test or are otherwise tracking events for everyone, then we don't want to overload our API, so we don't send non-Nobi events to our backend, we only send them to Mixpanel.
        if (!trackAllEvents || conversationId) {
            ApiClient.sendEvent(merchantId, event);
        }

        console.log(`Tracking event with event logger: ${name} with details: ${JSON.stringify(details)}`);
        MixpanelClient.trackOnsiteEvent(merchantId, name, details);
    }
}

export default NobiEventTracker;
