import ShouldSeeNobi from "./ShouldSeeNobi";
import ApiClient from "../service_clients/ApiClient";
import NobiEventTracker from "./NobiEventTracker";
import NobiEvents from "./Events";

export const ShopifyEventSubscriber = {
    subscribeToShopifyEvents: (merchantId, analytics) => {
        if (!analytics) {
            console.error('Analytics object not found. Make sure you are running on Shopify.');
            return;
        }

        let merchant;
        let trackAllEvents = false;
        let didUserHaveAccessToNobi = null;
        let queuedEvents = [];

        function sendOrQueueEvent(eventName, details) {
            if (merchant) {
                NobiEventTracker.trackEvent(merchantId, eventName, trackAllEvents, didUserHaveAccessToNobi, details);
            }
            else {
                queuedEvents.push({ eventName, details });
            }
        }

        function calculateMerchantAbGroupAndSendQueuedEvents() {
            if (merchant.isAbTestActive) {
                trackAllEvents = true;
            }
            didUserHaveAccessToNobi = ShouldSeeNobi(merchant);
            console.log("Track all events: " + trackAllEvents);

            // Process any events that were queued
            queuedEvents.forEach(queued => {
                NobiEventTracker.trackEvent(merchantId, queued.eventName, trackAllEvents, didUserHaveAccessToNobi, queued.details);
            });
            queuedEvents = [];
        }

        if (!window.Nobi.merchant) {
            ApiClient.getMerchant(merchantId, (retrievedMerchant, error) => {
                if (error) {
                    console.error("Error fetching merchant data:");
                    console.error(error);
                    return;
                }
                console.log("Fetched merchant data:");
                console.log(retrievedMerchant);

                window.Nobi.merchant = retrievedMerchant;
                merchant = retrievedMerchant;
                calculateMerchantAbGroupAndSendQueuedEvents();
            });
        }
        else {
            merchant = window.Nobi.merchant;
            calculateMerchantAbGroupAndSendQueuedEvents();
        }

        // Subscribe to various events, using the helper to either send or queue them.
        analytics.subscribe(NobiEvents.ShopifySearchSubmittedMetric, event => {
            if (event?.data) {
                let detailsToSend = {
                    query: event.data.searchResult?.query,
                    searchEngine: "Shopify Default"
                }
                sendOrQueueEvent(NobiEvents.Search, detailsToSend);
                sendOrQueueEvent(NobiEvents.SearchOnShopify, detailsToSend);
            }
        });

        analytics.subscribe(NobiEvents.ShopifyProductViewedMetric, event => {
            if (event?.data) {
                let detailsToSend = {
                    productVariantInternalId: event.data.productVariant.id
                }
                sendOrQueueEvent(NobiEvents.ProductViewed, detailsToSend);
            }
        });

        function notifyCartAssistantOfProductAddedToCart(event) {
            // See Shopify Web Pixel API for Product Added to Cart: https://shopify.dev/docs/api/web-pixels-api/standard-events/product_added_to_cart
            let details = {
                productInternalId: event.data.cartLine?.merchandise?.product?.id,
                productVariantInternalId: event.data.cartLine?.merchandise?.id,
            };
            window.parent.postMessage(details, "*");
        }

        analytics.subscribe(NobiEvents.ShopifyProductAddedToCartMetric, event => {
            if (event?.data) {
                let detailsToSend = {
                    cost: event.data.cartLine?.cost.totalAmount.amount,
                    currencyCode: event.data.cartLine?.cost.totalAmount.currencyCode,
                    productVariantInternalId: event.data.cartLine?.merchandise?.id,
                    quantity: event.data.cartLine?.quantity
                }
                sendOrQueueEvent(NobiEvents.ProductAddedToCart, detailsToSend);
                notifyCartAssistantOfProductAddedToCart(event);
            }
        });

        analytics.subscribe(NobiEvents.ShopifyProductRemovedFromCartMetric, event => {
            if (event?.data) {
                let detailsToSend = {
                    cost: event.data.cartLine?.cost.totalAmount.amount,
                    currencyCode: event.data.cartLine?.cost.totalAmount.currencyCode,
                    productVariantInternalId: event.data.cartLine?.merchandise?.id,
                    quantity: event.data.cartLine?.quantity
                }
                sendOrQueueEvent(NobiEvents.ProductRemovedFromCart, detailsToSend);
            }
        });

        analytics.subscribe(NobiEvents.ShopifyCheckoutCompletedMetric, event => {
            if (event?.data) {
                let variants = [];
                event.data.checkout?.lineItems?.forEach(lineItem => {
                    variants.push({
                        productVariantInternalId: lineItem.variant?.id,
                        quantity: lineItem.quantity
                    });
                });
                let detailsToSend = {
                    currencyCode: event.data.checkout?.currencyCode,
                    subtotalPrice: event.data.checkout?.subtotalPrice.amount,
                    totalPrice: event.data.checkout?.totalPrice.amount,
                    variants: JSON.stringify(variants),
                    totalItems: variants.map(v => v.quantity).reduce((a, b) => a + b, 0),
                    totalVariants: variants.length
                }
                sendOrQueueEvent(NobiEvents.CheckoutCompleted, detailsToSend);
            }
        });
    }
}
