import mixpanel from 'mixpanel-browser';

if (process.env.NODE_ENV === 'production') {
    mixpanel.init(
        "d7b429a44a7a4864684036c75553530c",
        {
            debug: true,
            track_pageview: false,
            persistence: 'localStorage',
            autocapture: false,
            ignore_dnt: true
        })
}

class MixpanelClient {
    static logSearchTime = (merchantId, message, time) => {
        this.track('Search Time', {
            'Nobi Merchant ID': merchantId,
            'Nobi Message': message,
            'Nobi Search Time In Milliseconds': time
        });
    }

    static trackOnsiteEvent = (merchantId, name, details) => {
        this.track(name, {
            'Nobi Merchant ID': merchantId,
            ...details
        });
    }

    static track(...args) {
        if (process.env.NODE_ENV === 'production') {
            try {
                mixpanel.track(...args);
            }
            catch (e) {
                console.error(e);
            }
        }
    }
}

export default MixpanelClient;